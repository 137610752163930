@import "./src/assets/styles/index.scss";
.solution {
    width: 100%;
    margin: auto;

    &-carousel {
        background: $redColor;
        background-size: 100%;
    }

    &-item-header {
        width: var(--header-width);
        box-sizing: border-box;
        position: relative;
        z-index: 9;
        margin: auto;
        padding: 10px 0;
        color: #fff;
        background-image: url(../../assets/images/bj02.jpg);
        background-size: 100% auto;

        &-title {
            width: var(--body-width);
            margin: auto;
            display: flex;
            flex-wrap: wrap;

            >div {
                display: inline-flex;
                align-items: center;

                span {
                    font-size: 20px;
                    margin-left: 8px;
                    margin-top: 10px;
                    font-weight: 300;
                    color: #8d8d8d;
                }

                p {
                    font-size: 24px;
                    margin: 12px 0;
                }
            }
        }
    }

    &-body {
        margin: 60px 0;

        &-content {
            padding-top: 60px;
            width: var(--body-width);
            text-indent: 2em;
            margin: auto;
            line-height: 32px;
            display: flex;
            >div{
                padding:20px 40px
            }
        }
        &-content:nth-child(2n + 1){
            flex-direction: row-reverse;
        }
        img {
            display: block;
        }

        // img:nth-child(odd){
        //     float:left;
        // }
        // img:nth-child(even){
        //     float:right;
        // }
    }

    &-navigation {
        position: fixed;
        top:50%;
        left: 20px;
        width: 200px;
        box-sizing: border-box;
        // height: 240px;
        // background: #8d8d8d;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        z-index: 10;
        li {
            display: block;
            color: rgba(68, 68, 68, 0.6);
            font-size: 14px;
            height: 40px;
            display: flex;
            width: 100%;
            align-items: center;
            padding-bottom: 10px;
            position: relative;

            >span{
                cursor: pointer;
            }
        }

        &-indicator {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                width: 6px;
                height: 6px;
                background: rgba(68, 68, 68, .6);
                border-radius: 50%;
            }
        }



        @keyframes fs-nav-animation {
            0% {
                transform: scale(0);
            }
            50%{
                transform: scale(1.8);
            }
            55% {
                transform: scale(0);
            }
            100% {
                transform: scale(0);
            }
        }

        @keyframes bg-nav-animation {
            0%{
                transform: scale(1);
            }
            40%{
                transform: scale(1);
            }
            50%{
                transform: scale(1.1);
            }
            70%{
                transform: scale(.8);
            }
          
        }

        .active {
            font-weight: 600;
            font-size: 16px;
            color: rgb(68, 68, 68);
            border-color: #000;
            position: relative;
            display: flex;
            align-items: center;

            .solution-navigation-indicator::after {
                position: absolute;
                content: '';
                background: #fff;
                width: 10px;
                height: 10px;
                animation: fs-nav-animation 1.2s ease-out normal infinite;
                border-radius: 50%;
                box-sizing: content-box;
                transition: all 0.2s ease-in-out;
                position: absolute;
            }

            .solution-navigation-indicator {
                span {
                    background: #000;
                    width: 16px;
                    height: 16px;
                    animation: bg-nav-animation 1.2s ease-out normal infinite;
                }
            }
        }


        li:hover {
            font-size: 16px;
            color: rgb(68, 68, 68);
        }

    }

    // &-navigation::after{
    //     content: '';
    //     background: #8d8d8d;
    //     width: 2px;
    //     height: 151px;
    //     position: absolute;
    //     left: 0px;
    //     bottom: 0px;
    // }
}