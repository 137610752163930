@import "./src/assets/styles/index.scss";
.position{
    display: flex;
    // width: var(--body-width);
    height: 100%;
    margin: auto;
    position: relative;
    &-body{
        width:100%;
        margin: auto;
        height: 100%;
        display: flex;
        z-index: 9;
        position: relative;
        justify-content: center;
        overflow: hidden;
        &-indicator{
            position: absolute;
            bottom: 30px;
            display: flex;
            
            .active{
                // width: 20px;
                background: $hoveColor;
            }
            div{
                margin:0 8px;
                >i{
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: #fff;
                    transition-property: all;
                    transition-duration: 0.5s;
                }
            }
        }
        .el-carousel__arrow{
            background: rgba(0, 0, 0, 0);
            // width: 56px;
            // height: 56px;
            // margin: 0 80px;
            position: absolute!important;
            border-radius:0!important;
            opacity: .3;
            bottom: 20px !important;
        }
        .el-carousel__arrow--left{
            left: -40px;
        }
        .el-carousel__arrow--right{
            right: -40px;
        }
        .el-carousel__arrow:hover{
            border: 2px rgba(255, 255, 255,1) solid;
            opacity:1;
            // border-color:$hoveColor;
            border: none;
            box-shadow: none;
            color: $hoveColor;
        }
    }
}